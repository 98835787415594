import { useEffect, useState } from 'react';
import useScript from 'react-script-hook';
import { VitalLinkOptions } from './types';
import { createVital, VitalFactory } from './factory';

const noop = () => {};

const VITAL_LINK_STABLE_URL = 'https://link.tryvital.io/initialize.js';

/**
 * This hook loads Vital script and manages the Vital Link creation for you.
 * You get easy open & exit methods to call and loading & error states.
 *
 * This will destroy the Vital UI on un-mounting so it's up to you to be
 * graceful to the user.
 *
 * A new Vital instance is created every time the token and products options change.
 * It's up to you to prevent unnecessary re-creations on re-render.
 */
export const useVitalLink = (options: VitalLinkOptions) => {
  // Asynchronously load the vital/link/stable url into the DOM
  const [loading, error] = useScript({
    src: VITAL_LINK_STABLE_URL,
    checkForExisting: true,
  });

  // internal state
  const [vital, setVital] = useState<VitalFactory | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    // If the link.js script is still loading, return prematurely
    if (loading) {
      return;
    }

    if (error || !window.Vital) {
      // eslint-disable-next-line no-console
      console.error('Error loading Vital', error);
      return;
    }

    // if an old vital instance exists, destroy it before
    // creating a new one
    if (vital != null) {
      vital.exit({ force: true }, () => vital.destroy());
    }

    const next = createVital({
      ...options,
      onLoad: () => {
        setIframeLoaded(true);
        options.onLoad && options.onLoad();
      },
    });

    setVital(next);

    // destroy the Vital iframe factory
    return () => next.exit({ force: true }, () => next.destroy());
  }, [loading, error]);

  return {
    error,
    ready: vital != null && (!loading || iframeLoaded),
    exit: vital ? vital.exit : noop,
    open: vital ? vital.open : noop,
  };
};
