import { VitalLinkOptions, Vital } from './types';

export interface VitalFactory {
  open: Function;
  exit: Function;
  destroy: Function;
}

interface FactoryInternalState {
  vital: Vital | null;
  open: boolean;
  onExitCallback: Function | null;
}

const renameKeyInObject = (
  o: { [index: string]: any },
  oldKey: string,
  newKey: string
): object => {
  const newObject = {};
  delete Object.assign(newObject, o, { [newKey]: o[oldKey] })[oldKey];
  return newObject;
};

/**
 * Wrap link handler creation and instance to clean up iframe via destroy() method
 */
export const createVital = (options: VitalLinkOptions) => {
  const state: FactoryInternalState = {
    vital: null,
    open: false,
    onExitCallback: null,
  };

  // If Vital is not available, throw an Error
  if (typeof window === 'undefined' || !window.Vital) {
    throw new Error('Vital not loaded');
  }

  const config = renameKeyInObject(
    options,
    'publicKey',
    'key'
  ) as VitalLinkOptions;

  state.vital = window.Vital.create({
    ...config,
    onExit: (...params: any) => {
      state.open = false;
      config.onExit && config.onExit(...params);
      state.onExitCallback && state.onExitCallback();
    },
  });

  const open = (token: string) => {
    if (!state.vital) {
      return;
    }
    state.open = true;
    state.onExitCallback = null;
    state.vital.open(token);
  };

  const exit = (exitOptions: any, callback: Function) => {
    if (!state.open || !state.vital) {
      callback && callback();
      return;
    }
    state.onExitCallback = callback;
    state.vital.exit(exitOptions);
    if (exitOptions && exitOptions.force) {
      state.open = false;
    }
  };

  const destroy = () => {
    if (!state.vital) {
      return;
    }

    state.vital.destroy();
    state.vital = null;
  };

  return {
    open,
    exit,
    destroy,
  };
};
