import React from 'react';

import { VitalLinkPropTypes } from './types';
import { useVitalLink } from './useVitalLink';

export const VitalLink: React.FC<VitalLinkPropTypes> = props => {
  const { children, style, className, ...config } = props;
  const { error, open } = useVitalLink({ ...config });

  return (
    <button
      disabled={Boolean(error)}
      type="button"
      className={className}
      style={{
        padding: '6px 4px',
        outline: 'none',
        background: '#FFFFFF',
        border: '2px solid #F1F1F1',
        borderRadius: '4px',
        ...style,
      }}
      onClick={() => open()}
    >
      {children}
    </button>
  );
};

VitalLink.displayName = 'VitalLink';
